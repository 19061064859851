import { connect } from 'react-redux';
import { push, copyTextToClipboard, isEmptyString } from '../../../common/v5/utils';
import { V5 } from '../../../common/path';
import KnowledgeBase from './knowledgeBase';
import SearchAnswer from './knowledgeBaseSearch';
import {
	agentAddKnowledgeBaseNode
	, agentRemoveKnowledgeBaseNode
	, fetchKnowledgeBase
	, fetchKnowledgeBaseCategory
	, fetchKnowledgeBaseRoot
	, fetchTableList
	, fetchMoreTableList
	, handleEditKnowledgeBaseList
	, handleRemoveKnowledgeBaseList
	, handleSaveKnowledgeBaseList
	, handleSearchAnswer
} from '../../../redux/actions/async/knowledgebase';
import {
	changeActiveTab
	, changeInput
	, changeSearchState
	, changeSource
	, createNew
	, loadingLibrary
	, resetEdit
	, selectTreeRoot
	, updateChatbotsID
} from '../../../redux/actions/knowledgebase';
import {
	changeAdminView,
} from '../../../redux/actions/admin';
import { selectToggleSideBar, selectCollapseSideBar, setMobileView, resetWorkflowView } from '../../../redux/actions/workflow';
import {
	onceKnowledgeBaseList
	, fetchAgentTemplates
	, decryptAndLoadErrand
} from '../../../redux/actions/async/workflow';
import { KBT_QUESTION, AGENT_FAV_AI_TRANSLATE_LANGUAGES, RPLY_ERRAND } from '../../../common/v5/constants';
import { isEmptyRichText, checkIfChatNotEmpty } from '../../../common/v5/helpers.js';
import { noForBotQuestionMemo, chatbotsIdListMemo, chatbotsIntentsMemo, chatbotsTrainingphrasesMemo } from '../../../redux/selectors/chatbot';
import { resetIntentList } from '../../../redux/actions/chatbot';
import { libraryFileArchivesSelector, openLibraryIdSelector, openQuestionIdSelector } from '../../../redux/selectors/library';
import { uploadOneAgentAttachment,
	generateAIAnswer,
	showOtherContactErrand,
	openErrandData,
	historyErrandContacts,
	fetchUserVote,
	loadKnowledgeBase,
	setUserVote,
	loadMoreOtherContacts,
	generateAIAnswerStreaming
} from '../../../redux/actions/async/errand';
import { agentAreaTemplatesSelector } from '../../../redux/selectors/workflow';
import {
	rewriteAnsBaseDataSelector,
	getRewriteAnswerBaseQuestionMemo,
	openErrandAreaLibraryMemo,
	currentActiveReply,
	knowledgeBaseDataSelector,
	knowledgeBaseListSelector,
	getCurrentErrandAreaData,
	getLlmSessionIdSelector
} from '../../../redux/selectors/errand';
import {
	clearInsertionContent
	, insertContent
 } from '../../../redux/actions/async/cke';
 import {
	fetchIntent,
	fetchIntentDirect,
	addPhrasesToChatbots,
	updateChatbotIntent,
	askTrainChatbotsWithLibraryQuestion
} from '../../../redux/actions/async/chatbot';
import { RewriteAnswerBoxContent } from '../../../components/v5/rewriteAnswerBase.js';
import { fetchAgentFavourite , updateAgentFavourite } from '../../../redux/actions/async/hmf';
import {
	showErrandRewriteAnswerPopup,
	setSetRAQuestion,
	updateAIAnswerstate,
	resetAIAnswer,
	selectedLibraryQuestion,
	handleLibraryAttachments,
	showErrandLibraryPopup,
	setKnowledgebaseQuestionNode,
	showLibraryAnswer,
	toggleRightSidePanel,
	expandHideOption,
	doExpandKnowledgeBasePanel,
	resetErrandLibrarySearch,
	selectErrandLibrary,
	setKBTreeParent,
	fetchAgentAssistRequest,
	doSetAIAnswerPanel
} from '../../../redux/actions/errand';
 import { CHAT_ADD_KB_FILE } from '../../../redux/constants/constants';

 const mapKnowledgeBaseStateToProp = (state, props) => {
	const kb = state.app.knowledgebase;
	const { chat, ui } = state.app.errand;
	return {
		activeTab: kb.activeTab
		, chatFeature: state.server.features.chat
		, deletedId: kb.deletedId
		, edit: kb.edit
		, fetchingQuestion: kb.fetchingQuestion
		, insertedNode: kb.insertedNode
		, noForBotQuestion: noForBotQuestionMemo(state)
		, removedNode: kb.removedNode
		, savedId: kb.savedId
		, selectedTreeRoot: kb.selectedTreeRoot
		, selectedTreeRootCategory: kb.selectedTreeRootCategory
		, selectedTreeRootLibrary: kb.selectedTreeRootLibrary
		, tableList: kb.tableList
		, treeList: kb.knowledgeBaseTreeList
		, treeRoots: kb.roots
		, archiveImgs: libraryFileArchivesSelector(state)
		, showMore: kb.showMore
		, templates: agentAreaTemplatesSelector(state)
		, insertionText: state.app.ckeditor.insertionText
		, fetchingLibrary: kb.fetchingLibrary
		, chatbotsIdList: chatbotsIdListMemo(state)
		, chatbotsIntents: chatbotsIntentsMemo(state)
		, chatbotTrainingPhrases: chatbotsTrainingphrasesMemo(state)
		, haveAIAnswerHistory: getRewriteAnswerBaseQuestionMemo(state,props)
		, errandLibraryList: state.app.workflow.knowledgeBaseList.data ? state.app.workflow.knowledgeBaseList.data.list : []
		, ui: ui.knowledgeBase
		, expandKnowledgeBasePanel: ui.expandKnowledgeBasePanel
		, searchSource: 0 // 0-either, 1-both, 2-question, 3-answer
		, currentReply: currentActiveReply(state)
		, kbList: knowledgeBaseListSelector(state, props)
		, chat
		, noForBotQuestion: noForBotQuestionMemo(state)
		, userVote: ui.knowledgeBase.userVote
		, areaDefaultLibrary: openErrandAreaLibraryMemo(state)
	};
};

const mapKnowledgeBaseDispatchToProp = (dispatch) => {
	return {
		onChangeActiveTab: (id) => {
			dispatch(changeActiveTab(id));
			dispatch(resetEdit());
		},
		onChangeTreeRoot: (id) => {
			dispatch(selectTreeRoot(id));
		},
		onCreateNew: (title) => {
			dispatch(createNew(title))
		},
		onEditQuestionRating: (id) => {
			dispatch(handleEditKnowledgeBaseList(KBT_QUESTION, id));
		},
		onFetchKnowledeBaseCategory: (id, param) => {
			dispatch(loadingLibrary(true));
			dispatch(fetchKnowledgeBaseCategory(id, param));
		},
		onFetchKnowledgeBase: (id, param) => {
			dispatch(loadingLibrary(true));
			dispatch(fetchKnowledgeBase(id, param));
			dispatch(resetAIAnswer("", false, false));
		},
		onHandleEdit: (type, id, library, forErrand) => {
			dispatch(handleEditKnowledgeBaseList(type, id));
			if(!forErrand) {
				dispatch(fetchUserVote(id));
				dispatch(resetAIAnswer("", false, false));
			} else {
				dispatch(selectErrandLibrary(library));
			}
		},
		onHandleRemove: (type, id) => {
			dispatch(handleRemoveKnowledgeBaseList(type, id));
		},
		onHandleSave: (type, data) => {
			dispatch(handleSaveKnowledgeBaseList(type, data));
		},
		onInsertKnowledgeBaseNode: (p) => {
			dispatch(agentAddKnowledgeBaseNode(p));
		},
		onLoadKnowledgeBaseCategory: (id) => {
			dispatch(fetchKnowledgeBaseCategory(id));
		},
		onLoadTreeRoots: () => {
			dispatch(fetchKnowledgeBaseRoot());
		},
		onRemoveNode: (p) => {
			dispatch(agentRemoveKnowledgeBaseNode(p));
		},
		onResetEdit: () => {
			dispatch(resetEdit());
		},
		onAppendTemplate: (value) => {
			dispatch(insertContent(value))
		},
		onResetInsertionText: () => {
			dispatch(clearInsertionContent());
		},
		onLoadAgentTemplates: () => {
			dispatch(fetchAgentTemplates())
		},
		onLoadList: (type, p, more) => {
			if(more){
				dispatch(fetchMoreTableList(type, p));
			}else{
				dispatch(fetchTableList(type, p));
			}
		},
		onDragnDropFile: (data, info) => {
			return dispatch(uploadOneAgentAttachment(
				{data, info}
				, "Library"
				, false
			));
		},
		onLoadChatbotIntents: (id) => {
			dispatch(fetchIntent(id));
		},
		onCheckingChatbotIntentsForQuestion: (id, intents, cbIds) => {
			let found = false, intentId = 0, chatbotId = 0;
			if (intents?.length) {
				for (let i = 0; i < intents.length; i++) {
					const intent = intents[i];
					if (intent.events?.length) {
						found = intent.events.some(event => event.includes(`workflow_libraryquestion_${id}`));
						if (found) {
							intentId = intent.id;
							chatbotId = intent.chatbot_id;
							break;
						}
					}
				}
				if (found) {
					dispatch(fetchIntentDirect(chatbotId, intentId));
					dispatch(updateChatbotsID(chatbotId, intentId));
				} else if (cbIds?.length) {
					const cbid = cbIds[0];
					dispatch(resetIntentList(cbid, intents));
				}
			}
		},
		onAddTrainingPhrases: (p) => {
			dispatch(addPhrasesToChatbots(p.libraryQuestionId, p.text, p.chatbots));
		},
		onRemoveTrainingPhrases: (chatbotId, intentId, data, completeData) => {
			dispatch(updateChatbotIntent(chatbotId, intentId, data, completeData));
		},
		onInitiateAIAnswer: (answer, haveAIAnswerHistory, activeLibrary) => {
			if(answer && answer !== "") {
				dispatch(setSetRAQuestion(answer));
			}
		},
		onMaximizeAIPanel: () => {
			dispatch(showErrandRewriteAnswerPopup(true));
		},
		onLoadErrandPreview : (id, threadId) => {
			if(threadId) {
				dispatch(openErrandData(id, { needBasic: true }, threadId))
				.then(() => {
					dispatch(historyErrandContacts(id, 0));
					dispatch(loadMoreOtherContacts(id, 0));
					dispatch(showOtherContactErrand(id, threadId));
				});
			}
		},
		onShowAnswerForErrand: (p, node) => {
			dispatch(showLibraryAnswer(p));
			dispatch(setKnowledgebaseQuestionNode(node));
			dispatch(fetchUserVote(p.id));
		},
		onRequestTrainChatbots: (id, chatbots) => {
			if (process.env.NODE_ENV !== 'production') {
				console.log(`dbg: request train chatbots intent library ${id}`);
			}
			return dispatch(askTrainChatbotsWithLibraryQuestion(id, chatbots));
		},
		handleAppendKnowledgeBase: (value, plain, attachments, reply, chat, qid, popupMode) => {
			if(chat) {
				dispatch({type:CHAT_ADD_KB_FILE,chat,attachments});
				//Append chat was handled on the previous operation
				if(popupMode) {
					dispatch(doExpandKnowledgeBasePanel(false));
				}
				dispatch(expandHideOption('KnowledgeBasePanel', 0, false));
				dispatch(toggleRightSidePanel(false));
				dispatch(resetErrandLibrarySearch());
				dispatch(resetEdit());
				return;
			}
			console.log("add knowledge into errand start.");
			dispatch(insertContent(value))
				.then(() => {
					console.log("add knowledge base into errand done.");
					dispatch(expandHideOption('KnowledgeBasePanel', 0, false));
					dispatch(toggleRightSidePanel(false));
				})
			if (reply == RPLY_ERRAND) {
				dispatch(selectedLibraryQuestion(qid, reply))
			}
			dispatch(handleLibraryAttachments(attachments, reply));
			if(popupMode) {
				dispatch(doExpandKnowledgeBasePanel(false));
			}
			dispatch(resetErrandLibrarySearch());
			dispatch(resetEdit());
		},
		onGetKnowledgeBaseBySearch: (id, param) =>{
			dispatch(loadKnowledgeBase(id, param, true));
		},
		onUpdateUserVote: (p) => {
			dispatch(setUserVote(p))
		},
		onSaveParentInfo(categoryId) {
			dispatch(setKBTreeParent(categoryId));
		},
		onToggleRewritePanelAnswer: (tgl, answer, isChat, newAIContext, AISession, questionId, action) =>{
			dispatch(doSetAIAnswerPanel(tgl));

			if (isEmptyString(answer)) {
				answer = answer.replace(/\s/g, '');
			}
			if (isChat) {
				const chatIsNotEmpty = checkIfChatNotEmpty();
				if (!chatIsNotEmpty) {
					answer = "";
				}
			}
			let isEmpty = false;
			if (answer !== "") {
				let parser = new DOMParser();
				let doc = parser.parseFromString(answer, 'text/html');
				if (doc.body.innerText === "") {
					isEmpty = true;
				}
			}
			if(!isEmpty) {
				dispatch(setSetRAQuestion(answer));
			}
			if(action) {
				if(action == "proof-read") {
					const streamId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
					const qId = "qLibrary-"+questionId.toString();
					const p = {
						content: answer,
						context: action,
						newAIContext,
						errandId: qId,
						sessionId: AISession
					}
					dispatch(updateAIAnswerstate(true));
					if (cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response") &&
						!features["openai-agent-assist-uses-openai"]) {
						dispatch(fetchAgentAssistRequest(qId, p.context, p.content, streamId));
						dispatch(generateAIAnswerStreaming(p, streamId));
					} else {
						dispatch(generateAIAnswer(p));
					}
				} else {
					//TODO (Future plan): To support other task shortcuts straight from the editor
				}
			}
		}
	}
};

const KnowledgeBaseCtnr = connect(mapKnowledgeBaseStateToProp, mapKnowledgeBaseDispatchToProp)(KnowledgeBase);

const mapSearchAnswerToProp = state => {
	const searchAnswer = state.app.searchAnswer;
	const wf = state.app.workflow;
	return {
		errandKbList: wf.knowledgeBaseList.data // state.domain.errandKnowledgeBaseList.data
		, searchSource: searchAnswer.source
		, searchState: searchAnswer.searchState
		, searchValue: searchAnswer.input
		, ui: wf.ui
		, treeList : state.app.knowledgebase.knowledgeBaseTreeList
		, edit: state.app.knowledgebase.edit

	};
};

const mapSearchAnswerDispatch = (dispatch) => {
	return {
		onCheckBoxChange: (source) => {
			dispatch(changeSource(source));
			dispatch(changeSearchState(true));
		},
		onChangeInput: (v) => {
			dispatch(changeInput(v));
		},
		onFetchKnowledgeBase: (id, param) => {
			dispatch(handleSearchAnswer(id, param));
		},
		onLoadKnowledgeBaseList: () => dispatch(onceKnowledgeBaseList()),
		onToggleSideBar: toggle => {
			dispatch(selectToggleSideBar(toggle));
		},
		onCollapseSideBar: (toggle) => {
			dispatch(selectCollapseSideBar(toggle));
		},
		onSearch: (toggle) => {
			dispatch(changeSearchState(toggle));
		},
		onChangeView: (view, url) => {
			dispatch(push(url)).then(() => dispatch(changeAdminView(view)));
		},
		onSetMobileView: (toggle) => {
			dispatch(setMobileView(toggle));
		},
		onMainView: () => {
			dispatch(resetWorkflowView());
		},
		simpleLoadAndOpen: (encoded, eid) => {
			dispatch(push(V5))
			.then(() => {
				dispatch(decryptAndLoadErrand(encoded, eid));
			});
		},
	};
};

export const SearchAnswerCtnr = connect(mapSearchAnswerToProp, mapSearchAnswerDispatch)(SearchAnswer);

const mapReWriteAnswer = (state, props) => {
	const wf = state.app.workflow, wfs = wf.fetchWfSettings
	, ui = state.app.errand.ui;
	return {
		show: ui.showAIAnswerPanel,
		currentQues: ui.rewriteAnswerBase.currentQues,
		newAIContext: ui.rewriteAnswerBase.newContext,
		AISession: getLlmSessionIdSelector(state, props),
		generatingAns: ui.rewriteAnswerBase.generatingAns,
		dataSrc: rewriteAnsBaseDataSelector(state, props),
		src: "KNOWLEDGE_BASE",
		verticalView: wfs.data.verticalView,
		agentFavouriteAITranslateLangs: state.app.header.agentFavourite[AGENT_FAV_AI_TRANSLATE_LANGUAGES],
		libraryId: openLibraryIdSelector(state, props),
		selectedLibraryQuestion: openQuestionIdSelector(state, props),
		currentAgentAssistContext: ui.rewriteAnswerBase.agentAssistContext
	};
}

const mapReWriteAnswerCallbacks = dispatch => {
	return {
		onLoad: () => {
			dispatch(fetchAgentFavourite(AGENT_FAV_AI_TRANSLATE_LANGUAGES));
		},
		generateAnswer: () => {
			dispatch(updateAIAnswerstate(true));
		},
		onUpdateQuestion: p => {
			dispatch(setSetRAQuestion(p));
		},
		onSend: (action, p) => {
			const streamId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
			if (action === "start-over") {
				//clear all AI answer history and reset the question to use current answer
				dispatch(resetAIAnswer(p.content, false, true));
				dispatch(setSetRAQuestion(p.content));
				dispatch(updateAIAnswerstate(true));
				if (cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response") &&
				!features["openai-agent-assist-uses-openai"]) {
					dispatch(fetchAgentAssistRequest(p.errandId, p.context, p.content, streamId));
					dispatch(generateAIAnswerStreaming(p, streamId));
				} else {
					dispatch(generateAIAnswer(p));
				}
			} else {
				let answer = p.content;
				if (isEmptyString(answer)) {
					answer = answer.replace(/\s/g, '');
				}
				if(answer !== ""){
					dispatch(setSetRAQuestion(answer));
					dispatch(updateAIAnswerstate(true));
					let translateUsingAgentAssist = false;
					if (action == "translate" && p.langCode && p.langCode == "ms") {
						translateUsingAgentAssist = true;
					}
					if (cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response") && (action !== "translate" || translateUsingAgentAssist) &&
					!features["openai-agent-assist-uses-openai"]) {
						dispatch(fetchAgentAssistRequest(p.errandId, p.context, p.content, streamId));
						dispatch(generateAIAnswerStreaming(p, streamId));
					} else {
						dispatch(generateAIAnswer(p));
					}
				}
			}
		},
		onResetAIAnswer: text => {
			dispatch(resetAIAnswer(text, false, false));
		},
		onCopy: (p) => {
			copyTextToClipboard(p);
		},
		onInsertAIAnswer: (value, isChat, src, isPopup, replace) => {
			const editor = CKEDITOR.instances["Library_answer"];
			if (editor) {
				if(replace) {
					editor.setData(value);
				} else {
					var selection = editor.getSelection();
					var ranges = selection.getRanges();
					if (ranges.length > 0) {
						const range = ranges[0];
						// Split the value into multiple paragraphs if plain text
						const paragraphs = value.includes('<')
							? value
							: value.split('\n\n').map(para => `<p>${para}</p>`).join('');
						// Create a CKEditor document fragment
						const fragment = new CKEDITOR.dom.documentFragment(editor.document);
						// Parse and append each paragraph to the fragment
						const tempContainer = CKEDITOR.dom.element.createFromHtml(`<div>${paragraphs}</div>`);
						while (tempContainer.getFirst()) {
							fragment.append(tempContainer.getFirst());
						}
						range.deleteContents();
						range.insertNode(fragment);
					} else {
						//no range, so append at the end
						editor.setData(editor.getData() + value);
					}
				}
			}
			if(isPopup) {
				dispatch(showErrandRewriteAnswerPopup(false));
			}
		},
		onUpdateLangFavourites: (code) => {
			dispatch(updateAgentFavourite(AGENT_FAV_AI_TRANSLATE_LANGUAGES, code))
		},
	}
}

export const RewriteAnswerCntr = connect(mapReWriteAnswer, mapReWriteAnswerCallbacks)(RewriteAnswerBoxContent);

export default KnowledgeBaseCtnr;
